<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="首页" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;">三元雀庄小程序管理后台。</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      allvalue: 0,
      nowithdraw: 0,
      value1: ''
    };
  },
  mounted() {

  },
  methods: {
    async getAllValue() {

    },
    toStartProcess() {
      this.$router.push("/startProcess");
    }
  }
};
</script>

<style scoped lang="less">
.overview {
  text-align: center;
  border-radius: 6px;
  background: #ffffff;
  // margin: 10px;
  box-shadow: 0px 0px 5px #888888;
  padding-top: 30px;
  padding-bottom: 40px;
  margin: 10px;
  .dividers{
    margin-top: -80px;
    height: 32px;
  }
  .numtext{
    font-size: 36px;
    color: #5cbb7a;
  }
  .nametext{
    font-size: 14px;
  }
}
.cards{
  text-align: center;
  margin: 10px;
  margin-top: 20px;
}
.search{
  margin: 10px;
  margin-bottom: 20px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 350px;
}
.newclue{
  float: right;
  padding-left: 60px;
  padding-right: 60px;
}
</style>
